<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper"></div>
    <el-form
      :model="form"
      :class="$style.promoGiftCodeViewForm"
      ref="form"
      label-position="right"
      label-width="240px"
    >
      <el-form-item label="Имя клиента" prop="clientName">
        <el-input v-model="form.clientName" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="Телефон клиента" prop="clientPhone">
        <el-input
          v-model="form.clientPhone"
          placeholder="+XXXXXXXXX, 9-15 символов"
          :readonly="true"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="Сумма" prop="value">
        <el-input
          v-model.number="form.value"
          placeholder="+XXXXXXXXX, 9-15 символов"
          :readonly="true"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="Мин. Сумма заказа для использования"
        prop="minPriceToApply"
      >
        <el-input-number
          v-model.number="form.minPriceToApply"
          controls-position="right"
          readonly
        >
        </el-input-number>
      </el-form-item>
      <el-form-item label="Тип">
        <el-input :value="getPromoTypeName(form.type)" :readonly="true">
        </el-input>
      </el-form-item>
      <el-form-item label="Код" prop="code">
        <el-input v-model="form.code" :readonly="true"></el-input>
      </el-form-item>
      <el-form-item label="Применим к товарам со скидкой" prop="isDiscountable">
        <el-checkbox v-model="form.isDiscountable"></el-checkbox>
      </el-form-item>
      <el-form-item label="Ассортименты" prop="assortmentIds">
        <el-select
          v-model="form.assortmentIds"
          filterable
          clearable
          remote
          multiple
          disabled
          placeholder="Выберите ассортименты"
          :class="$style.select"
        >
          <el-option
            v-for="item in form.assortmentIds"
            :key="item?.id"
            :label="item?.name"
            :value="item?.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import {
  PROMOCODE_TYPE_DISPLAY,
  PROMOCODE_TYPE,
} from '@/constants/promocodeTypes'
import notifications from '@/mixins/notifications'

export default {
  mixins: [notifications],
  data() {
    return {
      form: {
        code: '',
        clientName: '',
        clientPhone: '',
        value: 0,
        isDiscountable: false,
        minPriceToApply: 0,
        assortmentIds: [],
      },
    }
  },
  async created() {
    await this.getGiftPromo()
    if (this.form?.id) {
      await this.getAssortments()
    }
  },

  methods: {
    async getAssortments() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ProductsCore.AssortmentsActions.getList({
          promocodeId: this.form?.id,
        })

      loading.close()

      if (error) return

      this.form.assortmentIds = value.data
    },
    getPromoTypeName(type) {
      return type == PROMOCODE_TYPE.NOMINAL
        ? PROMOCODE_TYPE_DISPLAY.NOMINAL
        : PROMOCODE_TYPE_DISPLAY.PERCENTAGE
    },
    async getGiftPromo() {
      const loading = this.$loading({
        lock: true,
      })

      const { error, value } =
        await delivery.PaymentServiceCore.GiftPromosActions.getById(
          this.$route.params.id,
        )
      loading.close()
      if (error) {
        return this.showNotification('Не удалось получить промокод', 'error')
      }
      this.form = {
        ...value,
        assortmentIds: [],
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.promos {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .tag + .tag {
    margin-left: 0.625rem;
  }
  .buttonNewTag {
    margin-left: 0.625rem;
    height: 2rem;
    line-height: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .inputNewTag {
    width: 16rem;
    margin-left: 0.625rem;
    vertical-align: bottom;
  }
  label {
    margin: 0;
  }
  .filterItem > label {
    width: auto !important;
  }
  .promoGiftCodeViewForm {
    margin-top: 0.625rem;
  }
}
</style>
